import { action, makeObservable } from 'mobx';

import _, { set } from 'lodash';

import { EnumOptions, ProtectorType } from 'utils/api/types';

import FormBuilder from '../FormBuilder';

class QuestionLogicStore {
  constructor(private formBuilder: FormBuilder) {
    makeObservable(this);
  }

  @action.bound
  updatedQuestionValues(
    id: string,
    questionType?: ProtectorType,
    title?: string,
    lookupType?: string,
    properties?: { enum?: EnumOptions }
  ): void {
    if (!this.formBuilder.data) return;
    const sectionId = this.formBuilder.dragAndDropManager.findItem(id);
    if (!sectionId) return;
    this.formBuilder.data = set(
      { ...this.formBuilder.data },
      `properties.${sectionId}.properties.${id}.description`,
      title
    );
    this.formBuilder.data = set(
      { ...this.formBuilder.data },
      `properties.${sectionId}.properties.${id}.questionType`,
      questionType
    );

    if (questionType === ProtectorType.MultiChoice || questionType === ProtectorType.SingleChoice) {
      this.formBuilder.data = set(
        { ...this.formBuilder.data },
        `properties.${sectionId}.properties.${id}.properties`,
        properties
      );
    }
    // if the field is a lookupType field, set the lookupType field
    if (questionType === ProtectorType.Lookup) {
      this.formBuilder.data = set(
        { ...this.formBuilder.data },
        `properties.${sectionId}.properties.${id}.properties.lookupType`,
        lookupType
      );
    }
  }

  // delete question
  deleteQuestion = (sectionId: string, fieldId: string): void => {
    if (!this.formBuilder.data) return;
    this.formBuilder.data = set(
      { ...this.formBuilder.data },
      `properties.${sectionId}.order`,
      this.formBuilder.items[sectionId].filter((field) => field !== fieldId)
    );
    delete this.formBuilder.data.properties[sectionId].properties[fieldId];
    this.formBuilder.items = {
      ...this.formBuilder.items,
      [sectionId]: this.formBuilder.items[sectionId].filter((field) => field !== fieldId),
    };

    // remove the field from the required fields
    this.formBuilder.data = set(
      { ...this.formBuilder.data },
      `properties.${sectionId}.required`,
      this.formBuilder.data.properties[sectionId].required.filter((field) => field !== fieldId)
    );
  };

  // duplicate question
  duplicateQuestion = (sectionId: string, fieldId: string): void => {
    if (!this.formBuilder.data) return;
    const newFieldId = this.formBuilder.getNextContainerId();
    const newField = _.cloneDeep(this.formBuilder.data?.properties[sectionId].properties[fieldId]);

    const fieldIndex = this.formBuilder.data.properties[sectionId].order.indexOf(fieldId);

    // updating order
    this.formBuilder.data = set({ ...this.formBuilder.data }, `properties.${sectionId}.order`, [
      ...this.formBuilder.data.properties[sectionId].order.slice(0, fieldIndex + 1),
      newFieldId,
      ...this.formBuilder.data.properties[sectionId].order.slice(fieldIndex + 1),
    ]);

    // updating properties
    this.formBuilder.data = set(
      { ...this.formBuilder.data },
      `properties.${sectionId}.properties.${newFieldId}`,
      newField
    );

    this.formBuilder.items = {
      ...this.formBuilder.items,
      [sectionId]: [
        ...this.formBuilder.items[sectionId].slice(0, fieldIndex + 1),
        newFieldId,
        ...this.formBuilder.items[sectionId].slice(fieldIndex + 1),
      ],
    };

    // if duplicated field was required, make the new field required
    if (this.formBuilder.data.properties[sectionId].required.includes(fieldId)) {
      this.makeQuestionRequired(sectionId, newFieldId);
    }
  };

  // make question required
  makeQuestionRequired = (sectionId: string, fieldId: string): void => {
    if (!this.formBuilder.data) return;
    // if field was already required, remove it from required fields
    if (this.formBuilder.data.properties[sectionId].required.includes(fieldId)) {
      this.formBuilder.data = set(
        { ...this.formBuilder.data },
        `properties.${sectionId}.required`,
        this.formBuilder.data.properties[sectionId].required.filter((field) => field !== fieldId)
      );
    } else {
      // otherwise add it to required fields
      this.formBuilder.data = set({ ...this.formBuilder.data }, `properties.${sectionId}.required`, [
        ...this.formBuilder.data.properties[sectionId].required,
        fieldId,
      ]);
    }
  };

  isQuestionRequired = (sectionId: string, fieldId: string): boolean => {
    if (!this.formBuilder.data) return false;
    return this.formBuilder.data.properties[sectionId].required.includes(fieldId);
  };
}

export default QuestionLogicStore;
