import { styled } from 'styled-components';
import { Popover } from '@mui/material';

export const FieldProperties = styled.div`
  background: #fff;
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 8px;
  padding: 8px;
  justify-content: flex-end;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
`;

export const StyledPopover = styled(Popover)`
  box-shadow: none;
  border: none;
  border-radius: 8px;
`;
