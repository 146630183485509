import { UniqueIdentifier } from '@dnd-kit/core';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import { useStore } from 'utils/hooks/store';

import { QuestionContainer } from '../styles';

const Item: React.FC<{ id: UniqueIdentifier }> = ({ id }): React.ReactElement => {
  const { formBuilder } = useStore();

  const section = formBuilder.dragAndDropManager.findItem(id as string);
  if (!section) return <div>Section not found</div>;

  const data = formBuilder.data?.properties[section].properties[id];
  if (!data) return <div>Field not found</div>;

  return (
    <QuestionContainer>
      <div>
        <DragIndicatorIcon style={{ color: '#D9D9D9' }} />
      </div>
      <p>{data.description || 'Untitled'}</p>
    </QuestionContainer>
  );
};

export default Item;
