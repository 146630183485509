import React from 'react';

import { Divider, FormControlLabel, IconButton, MenuItem, Switch } from '@mui/material';

import { ContentCopy, MoreVert, DeleteOutline } from '@mui/icons-material';

import { ProtectorType } from 'utils/api/types';

import { useStore } from 'utils/hooks/store';

import { FieldProperties, StyledPopover } from './styles';
import { EditFieldFooter } from './types';

const FieldFooter: React.FC<EditFieldFooter> = ({
  questionType,
  sectionId,
  fieldId,
  onChangeFieldProperties,
}): React.ReactElement => {
  const { formBuilder } = useStore();

  const [anchorElConditionLogic, setAnchorElConditionLogic] = React.useState<null | HTMLElement>(null);

  const onDeleteField = (): void => {
    formBuilder.questionLogic.deleteQuestion(sectionId, fieldId);
  };

  const onDuplicateField = (): void => {
    formBuilder.questionLogic.duplicateQuestion(sectionId, fieldId);
  };

  const onMakeRequired = (): void => {
    formBuilder.questionLogic.makeQuestionRequired(sectionId, fieldId);
  };

  const onChangeMultipleOptions = (): void => {
    onChangeFieldProperties(
      questionType === ProtectorType.MultiChoice ? ProtectorType.SingleChoice : ProtectorType.MultiChoice,
      'questionType'
    );
  };

  const setConditionalLogic = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (anchorElConditionLogic) {
      setAnchorElConditionLogic(null);
    } else {
      setAnchorElConditionLogic(event.currentTarget);
    }
  };

  // to be implemented
  const onConditionLogic = (): void => {};

  const isRequired = formBuilder.questionLogic.isQuestionRequired(sectionId as string, fieldId as string);

  return (
    <FieldProperties>
      {/* if the questionType is MultiChoice add Switch */}
      {(questionType === ProtectorType.MultiChoice || questionType === ProtectorType.SingleChoice) && (
        <>
          <FormControlLabel
            style={{ marginRight: 0 }}
            control={
              <Switch
                checked={questionType === ProtectorType.MultiChoice}
                onClick={onChangeMultipleOptions}
                name="multiple"
                size="small"
              />
            }
            label={<p style={{ fontSize: '14px', margin: 0, padding: 0 }}>Multiple answers</p>}
          />
          <Divider orientation="vertical" flexItem style={{ margin: '8px' }} />
        </>
      )}
      <FormControlLabel
        style={{ marginRight: 0 }}
        control={<Switch checked={isRequired} onClick={onMakeRequired} name="required" size="small" />}
        label={<p style={{ fontSize: '14px', margin: 0, padding: 0 }}>Required</p>}
      />
      <Divider orientation="vertical" flexItem style={{ margin: '8px' }} />

      <IconButton size="small" onClick={onDuplicateField}>
        <ContentCopy />
      </IconButton>
      <IconButton size="small" onClick={onDeleteField}>
        <DeleteOutline />
      </IconButton>
      {(questionType === ProtectorType.MultiChoice || questionType === ProtectorType.SingleChoice) && (
        <>
          <IconButton size="small" onClick={setConditionalLogic}>
            <MoreVert />
          </IconButton>

          <StyledPopover
            id="popover-condition-logic"
            data-testid="popover-condition-logic"
            open={Boolean(anchorElConditionLogic)}
            anchorEl={anchorElConditionLogic}
            onClose={() => setAnchorElConditionLogic(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <MenuItem id="condition-button" onClick={onConditionLogic} disabled>
              Go to section based on answer
            </MenuItem>
          </StyledPopover>
        </>
      )}
    </FieldProperties>
  );
};

export default FieldFooter;
