import { action, makeObservable } from 'mobx';

import { AxiosError } from 'axios';

import { reverse } from 'named-urls';

import _ from 'lodash';

import RootStore from 'stores/Root';

import API from 'utils/api';

import { EntityPropertiesType, FormBuilderType } from 'utils/api/types';

import routes from 'core/routes';

import { Items } from 'containers/LayoutEditor/components/Layout/types';

import FormBuilder from '../FormBuilder';

class FormBuilderDataManager {
  store: RootStore;

  api: typeof API;

  constructor(rootStore: RootStore, api: typeof API, private formBuilder: FormBuilder) {
    this.store = rootStore;
    this.api = api;
    makeObservable(this);
  }

  @action.bound
  async loadLayout(moduleName: string, formId: string): Promise<void> {
    this.formBuilder.isLoaded = false;
    this.formBuilder.formId = formId;
    // if the formId is new, we create a new layout
    if (formId === 'new') {
      this.formBuilder.data = {
        order: [],
        properties: {},
      };
      this.formBuilder.loadedData = {
        order: [],
        properties: {},
      };
      this.formBuilder.lastPublished = undefined;
      this.transformDataForDisplay();
      this.formBuilder.formName = '';
      this.formBuilder.isLoaded = true;
      return;
    }
    try {
      const {
        data: { form, name, updatedAt },
      } = await this.api.loadEntityForm(moduleName, formId)();
      this.formBuilder.formName = name;
      this.formBuilder.data = (form as unknown) as FormBuilderType;
      this.formBuilder.loadedData = (form as unknown) as FormBuilderType;
      this.formBuilder.lastPublished = updatedAt;
      this.formBuilder.containers = form.order;
      this.transformDataForDisplay();
      const fieldsIds = Object.values(this.formBuilder.items).flat();
      this.store.entityFields.changeFieldsToDisabled(fieldsIds as string[]);
      this.formBuilder.error = undefined;
    } catch (err) {
      const e = err as AxiosError;
      this.formBuilder.error = e;
    } finally {
      this.formBuilder.isLoaded = true;
    }
  }

  // SAVING
  @action.bound
  async saveForm(): Promise<void> {
    // check if there is no empty section
    if (!this.formBuilder.validation.validateEmptySections()) {
      return;
    }

    // check if all sections names are unique
    if (!this.formBuilder.validation.validateSectionUniqueness()) {
      return;
    }

    // if the form is new, we create a new layout
    if (!this.formBuilder.validation.validateFormName()) {
      return;
    }

    const transformed = this.transformDataForSave();

    // check if the all the questions keys are unique
    if (!this.formBuilder.validation.validateQuestionsUniqueness(transformed)) {
      return;
    }

    if (this.formBuilder.formId === 'new') {
      try {
        const created = await this.api.createEntityForm('events', {
          name: this.formBuilder.formName,
          form: transformed,
        })();
        this.formBuilder.formId = created.data.uuid;
        this.formBuilder.lastPublished = created.data.updatedAt;
        this.formBuilder.contentOfLayoutWasEdited = false;
        this.store.notification.enqueueSuccessSnackbar('Form created successfully');
        this.store.routing.push(
          reverse(routes.dashboard.objectManager.details.formBuilder.details, {
            moduleName: 'events',
            formId: created.data.uuid,
          })
        );
      } catch (err) {
        const e = err as AxiosError;
        this.formBuilder.error = e;
        this.store.notification.enqueueErrorSnackbar(e.response?.data.message || 'Could not create form');
      }
      return;
    }

    try {
      const updated = await this.api.updateEntityForm('events', this.formBuilder.formId, {
        name: this.formBuilder.formName,
        form: transformed,
      })();
      this.formBuilder.contentOfLayoutWasEdited = false;
      this.formBuilder.lastPublished = updated.data.updatedAt;
      this.store.notification.enqueueSuccessSnackbar('Form published successfully');
    } catch (err) {
      const e = err as AxiosError;
      this.formBuilder.error = e;
      this.store.notification.enqueueErrorSnackbar(e.response?.data.message || 'Could not save form');
    }
  }

  @action.bound
  transformDataForSave(): FormBuilderType {
    const result: {
      order: string[];
      properties: EntityPropertiesType;
    } = {
      order: [],
      properties: {},
    };

    const updatedContainers = this.formBuilder.containers.map((containerKey) => {
      if (!this.formBuilder.items[containerKey]) return containerKey;

      const { title } = this.formBuilder.sectionLogic.getSectionTitleAndDescription(containerKey);

      _.set(result.properties, containerKey, {
        order: this.formBuilder.items[containerKey],
        title,
        description: this.formBuilder.data?.properties[containerKey]?.description,
        required: this.formBuilder.data?.properties[containerKey].required,
        properties: this.formBuilder.data?.properties[containerKey].properties,
      });

      return containerKey;
    });

    result.order = updatedContainers;

    return (result as unknown) as FormBuilderType;
  }

  @action.bound
  transformDataForDisplay(): void {
    if (!this.formBuilder.data) return;
    const result: Items = {};

    this.formBuilder.data.order.forEach((key: string) => {
      result[key] = this.formBuilder.data!.properties[key].order;
    });
    this.formBuilder.items = result;
  }
}

export default FormBuilderDataManager;
