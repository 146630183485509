import { makeObservable } from 'mobx';

import RootStore from 'stores/Root';

import { FormBuilderType } from 'utils/api/types';

import FormBuilder from '../FormBuilder';

class FormBuilderValidation {
  store: RootStore;

  constructor(rootStore: RootStore, private formBuilder: FormBuilder) {
    this.store = rootStore;
    makeObservable(this);
  }

  validateSectionUniqueness(): boolean {
    const sectionTitles = this.formBuilder.containers.map(
      (container) => this.formBuilder.sectionLogic.getSectionTitleAndDescription(container).title
    );
    const uniqueSectionTitles = new Set(sectionTitles);
    if (sectionTitles.length !== uniqueSectionTitles.size) {
      this.store.notification.enqueueErrorSnackbar('Section names must be unique');
      return false;
    }
    return true;
  }

  validateEmptySections(): boolean {
    const emptySections = this.formBuilder.containers.filter(
      (container) => this.formBuilder.items[container].length === 0
    );
    if (emptySections.length > 0) {
      this.store.notification.enqueueErrorSnackbar('Sections cannot be empty');
      return false;
    }
    return true;
  }

  validateFormName(): boolean {
    if (this.formBuilder.formName === '') {
      this.store.notification.enqueueErrorSnackbar('Form name cannot be empty');
      return false;
    }
    return true;
  }

  validateQuestionsUniqueness(transformed: FormBuilderType): boolean {
    const allKeys: string[] = [];
    Object.values(transformed.properties).forEach((section) => {
      allKeys.push(...section.order);
    });

    const uniqueKeys = new Set(allKeys);
    if (allKeys.length !== uniqueKeys.size) {
      this.store.notification.enqueueErrorSnackbar('Question keys must be unique');
      return false;
    }
    return true;
  }
}

export default FormBuilderValidation;
