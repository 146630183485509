import React from 'react';

import ShortTextIcon from 'components/Icons/ShortTextIcon';
import LongTextIcon from 'components/Icons/LongTextIcon';
import DateIcon from 'components/Icons/DateIcon';
import UserPickerIcon from 'components/Icons/UserPickerIcon';
import LocationIcon from 'components/Icons/LocationIcon';
import NumberIcon from 'components/Icons/NumberIcon';
import DateAndTimeIcon from 'components/Icons/DateAndTimeIcon';
import TimeIcon from 'components/Icons/TimeIcon';
import LookupIcon from 'components/Icons/LookupIcon';

import { ProtectorType } from 'utils/api/types';
import DropdownIcon from 'components/Icons/DropdownIcon';

const EXCLUDED_PROTECTOR_TYPES = new Set([
  ProtectorType.Section,
  ProtectorType.Status,
  ProtectorType.SingleChoice,
  ProtectorType.Image,
]);

export const possibleProtectorTypesForQuestions = Object.values(ProtectorType).filter(
  (type) => !EXCLUDED_PROTECTOR_TYPES.has(type)
);

type ProtectorTypeConfig = {
  displayName: string;
  icon: JSX.Element | null;
};

const protectorTypeConfig: Record<ProtectorType, ProtectorTypeConfig> = {
  [ProtectorType.ShortText]: { displayName: 'Short Text', icon: <ShortTextIcon /> },
  [ProtectorType.LongText]: { displayName: 'Long Text', icon: <LongTextIcon /> },
  [ProtectorType.Date]: { displayName: 'Date', icon: <DateIcon /> },
  [ProtectorType.User]: { displayName: 'User Picker', icon: <UserPickerIcon /> },
  [ProtectorType.Location]: { displayName: 'Location', icon: <LocationIcon /> },
  [ProtectorType.Number]: { displayName: 'Number', icon: <NumberIcon /> },
  [ProtectorType.DateTime]: { displayName: 'Date and Time', icon: <DateAndTimeIcon /> },
  [ProtectorType.Time]: { displayName: 'Time', icon: <TimeIcon /> },
  [ProtectorType.Lookup]: { displayName: 'Lookup', icon: <LookupIcon /> },
  [ProtectorType.MultiChoice]: { displayName: 'Dropdown', icon: <DropdownIcon /> },
  // Add excluded types for completeness (optional, or use default fallback)
  [ProtectorType.Section]: { displayName: 'Section', icon: null },
  [ProtectorType.Status]: { displayName: 'Status', icon: null },
  [ProtectorType.SingleChoice]: { displayName: 'Single Choice', icon: null },
  [ProtectorType.Image]: { displayName: 'Image', icon: null },
};

export const getProtectorType = (type: ProtectorType): ProtectorTypeConfig =>
  protectorTypeConfig[type] || { displayName: 'Unknown', icon: null };
