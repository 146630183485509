/* eslint-disable max-len */
import * as React from 'react';

function DropdownIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
      <rect x="0.5" y="0.5" width="13" height="13" rx="4.2" stroke="black" strokeOpacity="0.54" />
      <path
        d="M9.6775 4.83887L7 7.51053L4.3225 4.83887L3.5 5.66137L7 9.16137L10.5 5.66137L9.6775 4.83887Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  );
}

export default DropdownIcon;
