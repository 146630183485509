import React, { useState } from 'react';

import { useSortable } from '@dnd-kit/sortable';
import { ClickAwayListener, IconButton } from '@mui/material';

import { CSS } from '@dnd-kit/utilities';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import ClearIcon from '@mui/icons-material/Clear';

import { StyledTextField } from 'components/Input/styles';
import { useStore } from 'utils/hooks/store';

import { CircleIcon } from 'components/Icons/CircleIcon';

import { SquareIcon } from 'components/Icons/SquareIcon';

import { ProtectorType } from 'utils/api/types';

import { CenteredContainer, ItemContainer, OptionTitle } from './styles';
import { SortableItemProps } from './types';

function SortableItem({ id, options, setOptions, item, isLastOption, questionType }: SortableItemProps): JSX.Element {
  const { notification, formBuilder } = useStore();

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [name, setName] = useState(item.value);
  const [editingName, setEditingName] = useState(formBuilder.isDropdownOptionInEdit === item.key);

  React.useEffect(() => {
    if (editingName) {
      formBuilder.setDropdownOptionInEdit(item.key);
    }
  }, []);

  const handleEditNameOfOption = (value: string): void => {
    setName(value);
  };

  const handleClickAway = (): void => {
    if (!editingName) return;
    if (name.trim() === '') {
      notification.enqueueErrorSnackbar('Name cannot be empty. Please enter a value before saving.');
      return;
    }
    setEditingName(false);
    const newData = options.map((opt) =>
      opt.key === item.key
        ? {
            ...opt,
            value: name,
          }
        : opt
    );
    setOptions(newData);
    formBuilder.setDropdownOptionInEdit(undefined);
  };

  const onRemoveOption = (): void => {
    const newData = options.filter((opt) => opt.key !== item.key);
    setOptions(newData);
  };

  const onStartEditing = (): void => {
    formBuilder.setDropdownOptionInEdit(item.key);
    setEditingName(true);
  };

  return (
    <div
      ref={setNodeRef}
      style={{
        ...style,
        display: 'flex',
        alignItems: 'center',
        background: '#F5F5F5',
        borderRadius: '8px',
        border: '1px solid #E2E4E6',
        margin: '4px 0',
      }}
    >
      <div {...attributes} {...listeners} style={{ height: '20px', cursor: 'grab' }}>
        <DragIndicatorIcon style={{ fill: '#A3A1AC', height: '20px' }} />
      </div>
      <ItemContainer>
        <ClickAwayListener touchEvent="onTouchStart" mouseEvent="onMouseDown" onClickAway={handleClickAway}>
          <CenteredContainer key={`${item.key}_name`}>
            {questionType === ProtectorType.SingleChoice ? <CircleIcon /> : <SquareIcon />}
            {editingName ? (
              <StyledTextField
                autoFocus
                value={name}
                onChange={(e) => handleEditNameOfOption(e.target.value)}
                size="small"
                fullWidth
                sx={{
                  marginLeft: '8px',
                  '.MuiInputBase-input': {
                    fontSize: '12px !important',
                    padding: '0',
                  },
                  '.MuiInputBase-root fieldset': {
                    border: 'none !important',
                  },
                }}
              />
            ) : (
              <div role="button" tabIndex={0} onClick={onStartEditing}>
                <OptionTitle>{item.value}</OptionTitle>
              </div>
            )}
          </CenteredContainer>
        </ClickAwayListener>
        {!isLastOption && (
          <IconButton onClick={onRemoveOption}>
            <ClearIcon htmlColor="rgba(0, 0, 0, 0.54)" />
          </IconButton>
        )}
      </ItemContainer>
    </div>
  );
}

export default SortableItem;
