/* eslint-disable max-len */
import * as React from 'react';

export function CircleIcon(): React.ReactElement {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M8.0026 1.33301C4.3226 1.33301 1.33594 4.31967 1.33594 7.99967C1.33594 11.6797 4.3226 14.6663 8.0026 14.6663C11.6826 14.6663 14.6693 11.6797 14.6693 7.99967C14.6693 4.31967 11.6826 1.33301 8.0026 1.33301ZM8.0026 13.333C5.05594 13.333 2.66927 10.9463 2.66927 7.99967C2.66927 5.05301 5.05594 2.66634 8.0026 2.66634C10.9493 2.66634 13.3359 5.05301 13.3359 7.99967C13.3359 10.9463 10.9493 13.333 8.0026 13.333Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  );
}
